import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useIsMediaLG, useIsMediaXL, useIsMediaXXL, useIsMediaXXXL } from 'ERC/hooks';

import './LoadingBucket.less';

const LoadingBucket = (props) => {
  const [show, setShow] = useState(!props.delay);
  useEffect(() => {
    const th = props.delay && window.setTimeout(() => setShow(true), props.delay);
    return () => th && window.clearTimeout(th);
  }, [props.delay]);

  const isLG = useIsMediaLG();
  const isXL = useIsMediaXL();
  const isXXL = useIsMediaXXL();
  const isXXXL = useIsMediaXXXL();

  const items = useMemo(() => {
    let count = 1;
    if (!props.banner) {
      if (isXXXL && props.supportsLargeScreen) {
        count = 6;
      } else if (isXXL && props.supportsLargeScreen) {
        count = 5;
      } else if (isXL) {
        count = 4;
      } else if (isLG) {
        count = 3;
      } else {
        count = 2;
      }
    }

    if (props.items) {
      count = props.items;
    }

    return Array.from(Array(count).keys());
  }, [isLG, isXL, isXXL, isXXXL, props.banner, props.items, props.supportsLargeScreen]);

  if (!show) {
    return null;
  }

  return (
    <div className="loading-bucket-container">
      {props.title && (
        <h3 className="loading-title" aria-hidden />
      )}
      <div
        className={`loading-bucket${props.className ? ` ${props.className}` : ''}`}
        data-layout={props.banner ? 'banner' : 'carousel'}
      >
        {items.map((i) => (
          <div
            className={`loading-item${
              props.banner ? ' banner-wrapper' : ' card-wrapper'}${
              props.itemClassName ? ` ${props.itemClassName}` : ''}`}
            key={`item-${i}`}
          >
            <div className={`loading-card ${props.banner ? 'banner' : 'card'}`}>
              <div className="item-image" />
              <div className="item-body">
                <div className="item-title" />
                <div className="item-line" />
                <div className="item-line" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

LoadingBucket.defaultProps = {
  banner: false,
  className: null,
  delay: 0,
  itemClassName: null,
  supportsLargeScreen: false,
};

LoadingBucket.propTypes = {
  banner: PropTypes.bool,
  className: PropTypes.string,
  delay: PropTypes.number,
  itemClassName: PropTypes.string,
  items: PropTypes.number,
  supportsLargeScreen: PropTypes.bool,
  title: PropTypes.bool,
};

export default LoadingBucket;
